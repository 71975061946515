.leaflet-container {
  width: 100%;
  height: 100%;
}

.leaflet-control-zoom {
  width: 5000px;
}

.mapexample {
  width: 500px;
  margin: auto;
  height: 500px;
}

.update-pos-modal {
  height: auto;
  --width: 80%;
  --border-radius: 1rem;

  .minimap {
    height: 200px;
  }

  .content {
    padding: 2rem;
  }
}

.map-information-modal {
  align-items: flex-end;
  .modal-wrapper {
    border-radius: 10px 10px 0 0;
  }

  @media screen and (min-width: 768px) {
    padding: 2rem;
    .modal-wrapper {
      border-radius: 10px;
    }
  }

  .button-group {
    padding: 0 2rem 2rem 2rem;
    margin-top: 2rem;
    display: flex;
  }
}
