.qr_reader {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: flex-start;
    height: 100vh;
    position: relative;

    & > section > section {
        height: 100%;
    }

    &::after {
        position: absolute;
        content: "";
        top: 0;
        left: 0;
        right: 0;
        bottom: 0;
        pointer-events: none;
        background: rgba($color: #000, $alpha: 0);
        transition: 175ms ease-out;
        z-index: 100;
    }

    .loader {
        position: absolute;
        display: none;
        justify-content: center;
        align-items: center;
        width: 100%;
        top: 0;
        bottom: 0;
        z-index: 10;
    }

    .indicator {
        position: absolute;
        display: flex;
        justify-content: center;
        align-items: center;
        flex-direction: column;
        width: 100%;
        top: 0;
        bottom: 0;
        z-index: 10;

        p {
            position: relative;
            top: 4.5rem;
            text-shadow: 1px 1px 1px rgba(0, 0, 0, 1);
            text-transform: uppercase;
            font-size: 1.9rem;
            padding: 0 2.5rem;
            text-align: center;
            max-width: 25rem;
            color: rgba(var(--ion-color-dark-rgb, 0 ,0 ,0), 0.75)
        }
    }

    &.loading {
        &::after {
            transition: 225ms ease-in;
            background: rgba($color: #000, $alpha: 0.5);
        }

        .loader {
            display: flex;
        }
    }

    &.loading-preview {
        &:before{
            content: "";
            position: fixed;
            top: 0;left: 0;right: 0;bottom: 0;
            background-color: #d7d8da;
            z-index: 8;
        }
    }
}
