.scan__title {
    font-size: 1.9rem;
    color: var(--ion-color-dark, #000000);
    text-transform: uppercase;
    font-weight: 700;
    margin-bottom: 3rem;
}
.scan__text {
    font-size: 1.8rem;
    color: var(--ion-color-medium, #7c7C7C);
    line-height: 1.6;
}
.scan__wrap-text {
    padding: 2.5rem;
    max-width: 36rem;
    width: 100%;
    margin: 0 auto;
    text-align: center;
}
.lottie-wrapper{
    margin-top: -7.5rem;
}
