.btn_rounded {
  --border-radius: 3rem;
}

.no-ripple {
  --ripple-color: transparent;
  --background-activated: transparent;
}

ion-input {
  // border-bottom: 1px solid var(--ion-color-medium);
  // Ca casse l'affichage des inputs classique
}

ion-label {
  .md {
    margin-left: 1rem;
  }
}

.header-md::after {
  content: none;
}

ion-item.thumbnail {
  border-radius: 1.5rem;
  box-shadow: 0px 0px 7px 0px rgba(0, 0, 0, 0.05);

  &::part(native) {
    background-color: var(--ion-color-light);
  }
}

ion-icon.danger {
  margin-right: 1rem;
  color: var(--ion-color-danger);
}

.del-spot {
  position: absolute;
  left: 50%;
  bottom: 2rem;
  transform: translateX(-50%);

  ion-icon {
    margin-right: 1rem;
  }
}

// CLOSE MODAL
ion-modal {
  ion-icon.close-modal {
    position: absolute;
    z-index: 10;
    top: 1rem;
    right: 1rem;
    font-size: 3rem;
  }
}
.vertical-margin-20 {
  margin: 2rem 0;
}

@media screen and (min-width: 480px) {
  .list-img {
    display: grid;
    grid-template-columns: 1fr 1fr;
    gap: 2rem;
  }
}

ion-modal.auto-height {
  &.bottom {
    align-items: flex-end;
  }

  --height: auto;

  .ion-page {
    position: relative;
    display: block;
    contain: content;

    .inner-content {
      max-height: 80vh;
      overflow: auto;
      padding: 10px;
    }
  }
}

ion-button.full {
  width: 100%;
}
