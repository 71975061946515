.close-report {
  width: 4rem;
  height: 4rem;
  z-index: 10001;
  position: fixed;
  top: 3.5rem;
  right: 3rem;
  text-align: center;
  border-radius: 50%;
  box-shadow: 0px 0px 10px 0px rgba(0, 0, 0, 0.1);
  background: var(--ion-background-color);
  padding-top: 0.8rem;
  font-size: 2.4rem;
}