.ripple-parent {
  position: relative;
  overflow: hidden;
}

.content-page > div {
  height: 100%;
}

.content-page {
  --background: var(--ion-background-body-color, --ion-background-color);
}
