.dark ion-card {
  background: var(--ion-color-light-tint);
}

ion-card.card-click {
  cursor: pointer;
}

.spot-list {
  ion-item {
    --padding-start: 0;
    --ion-safe-area-left: 0;
  }
  ion-icon {
    margin-right: 5px;
  }
}

.intervention {
  text-align: center;
  color: rgba(var(--ion-color-dark-rgb), 0.5);
  font-size: 2.4rem;
  font-weight: 700;
  line-height: 1;
  margin: 1rem 0 4rem;
}

.job-select__modal--wrapper {
  height: 100%;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
}
.job-select__modal--text {
  font-size: 20px;
}
.job-select__modal--button {
  min-width: 10rem;
}

.ins-toast {
  position: fixed;
  bottom: 0;
  z-index: 10001;
  padding: 2.5rem 3rem;
  transition: transform 0.3s ease-in-out;
  transform: translateY(100%);

  &.is-open {
    transform: translateY(0%);
  }
}
.ins-toast__container {
  display: flex;
  align-items: center;
  background: white;
  padding: 1.5rem 2.5rem;
  border-radius: 8rem;
}
.ins-toast__icon {
  margin-right: 1rem;

  ion-icon {
    font-size: 4rem;
    margin-top: 0.1em;
  }
}
.ins-toast__message {
  font-size: 1.8rem;
  font-weight: 700;
  color: black;
  text-transform: uppercase;
}

.image-delete-wrapper {
  position: relative;
}
.image-delete {
  background-color: white;
  position: absolute;
  height: 2.4rem;
  width: 2.4rem;
  border-radius: 50%;
  top: 1rem;
  right: 0;
  box-shadow: 0 0 2px rgba(0, 0, 0, 0.16);
  pointer-events: none;

  &:before,
  &:after {
    content: '';
    width: 1.2rem;
    height: 2px;
    background: black;
    position: absolute;
    left: 50%;
    top: 50%;
    margin-top: -1px;
    margin-left: -0.6rem;
  }

  &:before {
    transform: rotate(45deg);
  }
  &:after {
    transform: rotate(-45deg);
  }
}

.report-type-list {
  list-style: none;
  padding: 0;
  margin: 0;
  display: flex;
  flex-wrap: wrap;
  flex-direction: row;
  justify-content: space-between;

  li {
    background-position: 50% calc(50% - 1rem);
    background-repeat: no-repeat;
    background-color: #ffffff;
    background-size: 8rem;
    position: relative;
    width: calc(50% - 1rem);
    margin-top: 2rem;
    box-shadow: 0px 0px 10px 0px rgba(0, 0, 0, 0.1);
    border-radius: 15px;

    @media screen and (min-width: 576px) {
      width: calc(33.333% - 1rem);
    }

    &:before {
      content: '';
      padding-top: 100%;
      display: block;
    }
  }
  span {
    position: absolute;
    padding: 1rem;
    text-align: center;
    width: 100%;
    bottom: 0;
    font-size: 14px;
    text-transform: uppercase;
    font-weight: 700;
    display: inline-block;
    line-height: 1.2;
    color: #000000;
  }
}

.report-back {
  color: var(--ion-text-color);
  text-decoration: none;

  ion-icon {
    position: relative;
    top: 2px;
    margin-right: 1rem;
  }
}

.report-spot-buttons {
  display: flex;
  margin-top: 2rem;
  justify-content: space-between;
  margin-bottom: 6rem;
}
.report-spot-button {
  flex: 1 1 18rem;
  max-width: 18rem;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  padding: 2.5rem 1rem;
  text-align: center;
  background-color: white;
  color: #000;
  box-shadow: 0px 0px 10px 0px rgba(0, 0, 0, 0.1);
  border-radius: 15px;
  font-size: 14px;
  text-transform: uppercase;
  line-height: 1.1;
  font-weight: 700;

  ion-icon {
    font-size: 2.4rem;
    margin-bottom: 1.5rem;
  }
}
.report-spot-separator {
  flex: 0 0 0;
  display: flex;
  align-items: center;
  padding: 1rem;
}
.report-image-btn {
  font-size: 20px;
  position: relative;
  top: 4px;
  margin-left: 15px;
}

// Signature electronique
.sig-wrapper {
  margin-top: 2rem;
  color: var(--ion-text-color, --ion-color-dark);
  position: relative;
}
.sig-canvas {
  margin-top: 1rem;
  background-color: #fff;
  box-shadow: 0px 0px 10px 0px rgba(0, 0, 0, 0.1);
  border-width: 1px;
  border-style: solid;
  border-color: var(
    --ion-item-border-color,
    var(--ion-border-color, var(--ion-color-step-250, #c8c7cc))
  );
}
.sig-clear {
  position: absolute;
  bottom: 0;
  left: 50%;
  transform: translateX(-50%);
  padding: 1rem;
  color: var(--ion-color-danger);
  display: flex;
  align-items: center;
  font-size: 20px;

  span {
    font-size: 16px;
    text-transform: uppercase;
    margin-left: 1rem;
  }
}
