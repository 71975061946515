.container-default {
    margin: 0 auto;
    max-width: 800px;
    width: 100%;
    padding: 0 3rem;
}

.container-default > div {
    width: 100%;
}

.container-child--flex {
    display: flex;
}

.container-default--center {
    min-height: 100%;
    display: flex;
    align-items: center;
    justify-content: center;
}

.container-default--small {
    max-width: 350px;
}

.container-default--padding-top {
    // padding-top: 128px;
    padding-top: 9rem;
}

.container-default--padding-bottom {
    // padding-bottom: 128px;
    padding-bottom: 9rem;
}

.container-default--no-padding {
    // padding-bottom: 128px;
    padding: 0;
}
