// HEADER
ion-header {
  position: fixed;
  top: 3.5rem;
  left: 3rem;
  width: calc(100vw - 6rem);
  text-align: right;
}
.search-icon {
  background-color: var(--ion-color-light);
  font-size: 2rem;
  width: 2rem;
  height: 2rem;
  padding: 1rem;
  border-radius: 50%;
  box-shadow: 0px 0px 10px 0px rgba(0, 0, 0, 0.1);
}

ion-item.thumbnail {
  border-radius: 1.5rem;
  box-shadow: 0px 0px 7px 0px rgba(0, 0, 0, 0.05);
}

.title-admin {
  font-size: 2.2rem;
  font-weight: bold;
}

.label-admin {
  font-size: 1.6rem;
  font-weight: bold;
  padding: 1rem 0;
}

// PLACES LIST

ion-card-title {
  font-size: 2.5rem;
  font-weight: bold;
}

.spots {
  margin-top: 3rem;
}

ion-item.list-item {
  margin: 1rem 0;
  --padding-start: 2.5rem;
  --padding-end: 2.5rem;
  --padding-top: 1.5rem;
  --padding-bottom: 1.5rem;
  border-radius: 1.5rem;
  box-shadow: 0px 0px 10px 0px rgba(0, 0, 0, 0.1);

  &::part(native) {
    background-color: var(--ion-color-light);
  }

  .name {
    color: var(--ion-color-dark);
    text-transform: uppercase;
    font-weight: 700;
  }

  .address {
    color: var(--ion-color-dark);
    text-transform: uppercase;
  }
}

// PLACE

.item-address {
  margin: 2rem 0;
  --padding-start: 1.8rem;
  --padding-end: 1.8rem;
  --padding-top: 0.5rem;
  --padding-bottom: 0.5rem;

  .left_part {
    display: flex;
    flex-direction: column;
    align-self: center;
    width: 100%;
    justify-content: space-between;
    .street {
      text-transform: lowercase;
    }
    .address-label {
      padding-left: 1rem;
    }
  }
  .spot_part {
    display: flex;
    align-self: center;
    width: 100%;
    .address {
      flex-grow: 1;
      text-align: right;
      text-transform: lowercase;
    }
    .adress-label {
      color: var(--ion-color-medium);
    }
  }
}

.spot-list {
  padding: 4rem 0;
  font-size: 2rem;
  font-weight: bold;
  &::part(slot) {
    margin: 2rem 0;
  }
}

.entrance-title {
  text-align: center;
  font-size: 2rem;
  margin: 3rem 0;
  font-weight: bold;
}

.entrance {
  margin-bottom: 5rem;

  .entrance-header {
    margin: 0 -1rem;
    display: flex;
    align-items: center;
    justify-content: space-between;
  }

  .entrance-name {
    padding: 1rem;
    font-size: 1.6rem;
    font-weight: bold;
  }

  .add-spot {
    padding: 1rem;
    font-size: 1.6rem;
    font-weight: normal;
  }
}

ion-item.spot {
  margin: 2rem 0;
  --padding-start: 2.5rem;
  --padding-end: 2.5rem;

  &::part(native) {
    background-color: var(--ion-color-light);
  }

  .name {
    color: var(--ion-color-dark);
    font-size: 1.6rem;
  }

  .address {
    color: var(--ion-color-dark);
    text-transform: uppercase;
  }
}

// Spot
ion-card {
  margin: 3rem 0;
  --background: var(--ion-color-light);
  box-shadow: 0px 0px 7px 0px rgba(0, 0, 0, 0.05);
}

.dark ion-card {
  --background: var(--ion-color-light-tint);
}

ion-card.card-click {
  cursor: pointer;
}

ion-card.rounder {
  border-radius: 30px;
}

.moreButton {
  font-size: 3rem;
  width: fit-content;
  display: flex;
  justify-self: center;
  margin: auto;
}

ion-card-title.code {
  font-size: 2rem;
}

.spot-name {
  font-size: 1.6rem;
}

.description-header {
  padding: 1.5rem;
  display: flex;
  border-bottom: 1px solid rgba(var(--ion-color-medium-rgb), 0.13);
}

.description-content {
  padding: 1.5rem;

  &.border {
    border-bottom: 1px solid rgba(var(--ion-color-medium-rgb), 0.13);
  }
  .description {
    margin-top: 1rem;
    display: block;
    font-size: 1.6rem;
    line-height: 2.8rem;
  }
}

.controler-container {
  margin: 2rem 0;

  ion-item {
    &::part(native) {
      display: flex;
    }
  }
}

.qr-modal {
  height: auto;
  --height: 43rem;
  --width: 70%;
  --border-radius: 1rem;

  .ion-page {
    padding: 4rem 0 0;
  }

  .qr-modal-text {
    padding: 0 2rem;
  }

  .qr-modal-content {
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    height: 100%;

    .qr-modal_header {
      font-size: 1.6rem;
      font-weight: bold;
    }

    .qr-modal_img {
      margin: 5rem auto;
      width: 14rem;
      height: 14rem;
    }

    .qr-modal_button {
      margin-top: auto;
      padding: 3rem 0;
      --padding-top: 3rem;
      --padding-bottom: 3rem;
      text-transform: unset;
      margin: 0;
      display: flex;
      align-items: center;
      justify-content: center;
      border-top: 1px solid rgba(var(--ion-color-medium-rgb), 0.13);

      ion-icon {
        margin-right: 1rem;
      }
    }
  }
}
.del-spot-modal {
  height: auto;
  --height: 15rem;
  --width: 70%;
  --border-radius: 1rem;

  .ion-page {
    padding: 4rem 2rem;
  }

  .del-spot-text {
    font-size: 1.8rem;
    font-weight: bold;
  }

  ion-button {
    margin: 3rem 5rem;
    width: 15rem;
  }
}

// SEARCH TAB BAR
.search {
  .tab {
    display: flex;
    flex-direction: row;
    justify-content: center;
    flex-wrap: wrap;
    width: 100%;

    ion-button {
      position: relative;
      width: 150px;
      // margin: 0 10px 10px 20px;
      // font-size: 0.8rem;
      // width: 22%;
      height: max-content;
      flex: 1;
      .in-button {
        display: flex;
        flex-direction: column;
        align-items: center;
        color: var(--ion-color-dark);
        padding: 0.675em 1em;

        .tab-label {
          margin-top: 5px;
          font-size: 1.2rem;
        }
      }
    }
  }

  ion-button.tab-button {
    &::part(native) {
      height: max-content;
    }
  }

  ion-button.button-solid .in-button {
    color: #000000 !important;
  }

  ion-button.button-solid ion-badge {
    background-color: #000000;
    color: #ffffff;
  }

  // Tab badge

  ion-badge {
    position: absolute;
    right: 10px;
    top: 10px;
    background-color: var(--ion-color-dark);
    color: var(--ion-color-light);
  }
}

ion-tab-bar {
  display: flex;
  justify-content: space-evenly;
}

.category-title {
  text-align: center;
  text-transform: uppercase;
  font-size: 2.5rem;
  margin-bottom: 2rem;
  margin-top: 1rem;
  font-weight: bold;
}

.error-center {
  display: flex;
  flex-direction: column;
  padding-top: 70%;
  max-width: 60%;
  margin: auto;
}

.chooseButton {
  width: 40%;
  margin: 0 30%;
}

// map
.clickableMarker {
  z-index: 1000;
}
