.container {
  text-align: center;
  width: 35rem;
  max-width: 100%;
  margin: 0 auto;
}

.logo {
  margin: 6rem auto 4rem;
  width: 13.5rem;
}

// Valentin 19/01, ça impacte la mise en page de tous les formulaires, et y a d'autres façons de faire
// .item-input {
//   margin: 2rem 0;
// }

.margin-top {
  margin-top: 2rem;
}

.margin-vertical {
  margin: 2rem 0;
}

.button-block {
  margin-top: 2rem;
}

.item {
  .forgot-password {
    text-decoration: underline;
    font-size: 1.2rem;
    cursor: pointer;
  }
}

.btn_wrapper {
  position: relative;
  overflow: visible;

  .error {
    position: absolute;
    bottom: calc(100% + 1rem);
    left: 50%;
    transform: translateX(-50%);
    width: 100%;
    text-align: center;
  }
}

.button-show-password {
  width: auto;
  --ripple-color: transparent;
  
  &::slotted([slot="end"]) {
    margin-left: 0;
  }
}

// ion-button {
//   --padding-top: 1.5rem;
//   --padding-bottom: 1.5rem;
//   width: 100%;
//   font-size: 1.9rem;
//   font-weight: bold;
// }

// MOT DE PASSE OUBLIE
ion-modal.forgot-password-modal {
  .modal-wrapper  {
    // width: 90%;
    // height: 65%;
    border-radius: 1rem;
  }

  .ion-page {
    padding: 4rem 2rem;
  }

  ion-icon {
    position: absolute;
    z-index: 10;
    top: 1rem;
    right: 1rem;
    font-size: 3rem;
  }
}
