ion-menu-button {
  --background: var(--ion-color-light);
  &::part(native) {
    width: 4rem;
    height: 4rem;
    padding: 1rem;
    border-radius: 50%;
    box-shadow: 0px 0px 10px 0px rgba(0, 0, 0, 0.1);
  }
}

ion-menu ion-button {
  width: 100%;
}

.menu_button {
  z-index: 10001;
  position: fixed;
  top: 3.5rem;
  left: 3rem;
}
.menu_button__text {
  width: auto;
  height: 50px;
  display: block;

  &::part(native) {
    width: auto;
    border-radius: 2rem;
  }
}

.menu_button__label {
  font-size: 16px;
  font-weight: bold;
  margin-left: 10px;
  color: var(--ion-color-dark, #000000);
}
.menu_button__label--danger {
  color: var(--ion-color-danger, #eb445a);
}

:host(.menu-side-start) .menu-inner {
  border-radius: 1.5rem;
  position: fixed;
  top: 2rem;
  bottom: 2rem;
  left: 2rem;
}
.change-role {
  margin-top: 1rem;
  padding: 0 1.5rem;
}
// .change-role-link {
//   color: var(--ion-color-light);
//   text-decoration: none;
//   font-size: 1.9rem;
// }

// Couleur de fond du menu ouvert (utiliser des var() pour le dark mode)

ion-menu {
  &::part(backdrop) {
    background-color: var(--ion-color-dark);
  }

  &::part(container) {
    margin-left: 2rem;
    width: var(--width);
    height: calc(100% - 4rem);
    top: 2rem;
    background: var(--ion-color-light);
    border-radius: 1.5rem;
    box-shadow: 0px 0px 10px 0px rgba(0, 0, 0, 0.1);
  }

  ion-menu-button {
    &::part(native) {
      margin-top: 1.5rem;
      margin-left: 1.5rem;
    }
  }

  ion-item.darkItem {
    display: flex;
    flex-direction: column;
    &::part(native) {
      padding-top: 1rem;
      font-size: 1.5rem;
    }
    ion-toggle {
      height: 2.5rem;
    }
  }

  ion-card.menu {
    width: 90%;
    align-items: center;
    margin: 2rem auto;
    ion-card-header {
      padding-bottom: 0;
      ion-card-subtitle .info {
        display: flex;
        .info-content {
          padding-left: 1rem;
        }
      }
      ion-card-title {
        display: flex;
        font-size: 2rem;
        align-items: center;
        .name {
          color: var(--ion-color-dark);
          font-size: 2rem;
          font-weight: bold;
          padding-left: 1rem;
        }
      }
    }
    .info {
      display: flex;
      font-size: 2rem;
      align-items: stretch;
      margin-top: 1rem;
      .info-content {
        font-size: 1.4rem;
        padding-left: 1rem;
      }
      .jobs {
        display: flex;
      }
    }
  }

  ion-content {
    .menu_list {
      flex-grow: 2;
    }

    .disconnection {
      position: absolute;
      bottom: 1rem;
      left: 1.5rem;
      right: 1.5rem;
      width: auto;

      ion-icon {
        margin-right: 2rem;
      }
    }
  }
}

// SearchBar

// nécessaire pour le dark mode (le texte de l'input)

.searchbar-input-container {
  .searchbar-input {
    color: var(--ion-color-dark);
  }
}
