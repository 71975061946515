.account-title {
  text-align: center;
  color: #b7d406;
}

.alert-wrapper.sc-ion-alert-md,
.alert-wrapper.sc-ion-alert-ios {
  max-width: 100% !important;
  width: 90% !important;
}

.alert-radio-label.sc-ion-alert-md,
.alert-radio-label.sc-ion-alert-ios {
  white-space: unset;
}
